<template>
  <div>

    <section class="banner_sec our_story">
      <div class="banner_hed">
        <h1 class="heading_2">The Serendipitous Story of PBE</h1>
      </div>
    </section>

    <!-- banner end -->

    <!-- over 20 start -->
    <section class="over_sec padding_100">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="over_box text_secondary text-center">
              <img src="../../assets/home/images/our_story/heart.png" alt="img">
              <h5 class="heading_5 font_bold">Over 20 years of serving the community. </h5>
              <p>
                For over 20 years, PBE has had the honor to provide stellar ergonomic services to organizations just
                like yours! With your help, support and partnership, we have helped employees resolve their ergonomic
                issues and remain healthy and productive both on and off the job!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- over 20 ne -->

    <!-- We have start -->

    <section class="image_text_sec pb_100 we_have_help corner-radius smartscreen">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-6 col-xl-6">
            <div class="image_left_side">
              <img src="../../assets/home/images/our_story/we_have.png" alt="">
            </div>
          </div>
          <div class="col-md-12 col-lg-6 col-xl-6">
            <div class="image_text_right_side">
              <h6>We have helped our clients save millions.</h6>
              <p class="mb_34">
                PBE has helped its clients reduce both employee downtime and expenditure as a direct result of training,
                education and consultation.
                Our years of experience allows us to craft the online management and training tools you need to take
                full control of your ergo program.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- We have end -->

    <!-- our unique start -->
    <section class="our_unique padding_100 bg_primary">
      <div class="container">
        <div class="row">
          <div class="col-md-12" id="get_one">
            <div class="our_unique_box text-center">
              <h2 class="heading_2 m-0 text_white font_bold">Our Unique Story</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- our unique end -->

    <!-- accordion start -->
    <section class="accordion_sec mt_50 mb_60 acc-small-pad">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 col-xxl-8">
            <div id="accordionExample" class="accordion">
              <div class="accordion-item" >
                <h2 id="headingOne" class="accordion-header">
                  <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                    aria-controls="collapseOne" class="accordion-button collapsed">
                    Unexpected Beginning
                  </button>
                </h2>
                <div id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                  class="accordion-collapse collapse">
                  <div class="accordion-body">
                    <p>
                      When Vivienne told her piano professor at Juilliard that her hands were hurting from the technique
                      building exercises that he assigned to her, he said “That’s great! You are developing muscles. No
                      pain, no gain.”
                    </p>
                    <p>
                      When she sought help at the medical clinic, she was prescribed pain killers and advised to cancel
                      her concerts and quit her day job (waiting tables and office temp work).
                    </p>
                    <p>
                      After much searching, Vivienne learned about a piano methodology along with a few other forms
                      of body work. This is how she learned to correlate what she was doing while playing piano (and
                      typing)
                      to what she was feeling. She learned to sit, move, play and feel better.
                    </p>
                    <p>
                      Excited by her discoveries, she began to help others and set out to find ways to bring her
                      knowledge
                      into companies and organizations to help as many people as possible.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item" id="get_two">
                <h2 id="headingTwo" class="accordion-header" @click="scrollone">
                  <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                    aria-controls="collapseTwo" class="accordion-button collapsed">

                    Chance Meeting
                  </button>
                </h2>
                <div id="collapseTwo" aria-labelledby="headingTwo" data-bs-parent="#accordionExample"
                  class="accordion-collapse collapse">
                  <div class="accordion-body">
                    <p>
                      This is where Andrew comes in. In between jobs and moonlighting
                      as a cab-driver, he picked up Vivienne as a passenger, completely
                      unaware that this ride would change the course of their lives.

                    </p>
                    <p>
                      With his sound advice and extensive background in athletics, urban
                      planning, business management and education, they joined forces
                      and put in many late nights creating course materials and
                      strategizing on how to build and grow PBE.
                    </p>

                  </div>
                </div>
              </div>
              <div class="accordion-item" id="get_three">
                <h2 id="headingThree" class="accordion-header" @click="scrollone3">
                  <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree" class="accordion-button collapsed">

                    Humble Beginnings
                  </button>
                </h2>
                <div id="collapseThree" aria-labelledby="headingThree" data-bs-parent="#accordionExample"
                  class="accordion-collapse collapse">
                  <div class="accordion-body">
                    <p>
                      They started out with a vision and a very small handful of
                      clients. The first leg of the PBE journey started out heavily
                      steeped in the world of Workers’ Comp Insurance and
                      helping those that were significantly injured by computer
                      desktop work.

                    </p>
                    <p>
                      Determined to broaden their audience, they worked
                      extremely hard to bring ergonomics out of the pidgeon hole
                      of “injury” and into the realm of prevention, best practices and
                      to make ergonomics an integral part of an employees’ and
                      employers’ work life.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item" id="get_four">
                <h2 id="headingFour" class="accordion-header" @click="scrollone4">
                  <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false"
                    aria-controls="collapseFour" class="accordion-button collapsed">

                    A Success Story
                  </button>
                </h2>
                <div id="collapseFour" aria-labelledby="headingFour" data-bs-parent="#accordionExample"
                  class="accordion-collapse collapse">
                  <div class="accordion-body">
                    <p>
                      Over time, PBE’s talented team of ergonomics grew, a tech team was born and a devoted group of
                      client support specialists were developed. Together, the PBE team has helped hundreds of
                      companies, thousands of employees and have developed many successful global ergo programs.
                    </p>
                    <p>
                      With our online ergo training, program management platforms and hundreds of videos later, we are
                      humbled and proud to service the awesome companies that are part of the PBE family.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- accordion end -->

    <!-- ready start -->
    <section class="ready_sec mb_100">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="ready_box text-center">
              <p class="font_size_22 text_secondary">Ready for a deeper dive into PBE. <router-link
                  :to="{ name: 'meet_the_team' }">
                  <span class="text_primary font_bold">Meet with the team!</span>
                </router-link>
              </p>
              <router-link class="btn btn_primary" :to="{ name: 'learn_more' }">
                Let’s Chat
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ready end -->
  </div>

</template>

<script>
  // import { mapGetters } from "vuex";
  import store from "@/store/index";
  import $ from "jquery";

  export default {
    name: "our_story",
    methods: {
      scrollone(){ 
      setTimeout(function() {
      const mediaQuery = window.matchMedia('(max-width: 767px)')
      if (mediaQuery.matches) {
        $('html,body').animate({ 
        scrollTop: $("#get_one").offset().top},
        'slow');
      }
      }, 500);
      },
       scrollone3(){ 
        setTimeout(function() {
        const mediaQuery = window.matchMedia('(max-width: 767px)')
        if (mediaQuery.matches) {
        $('html,body').animate({ 
        scrollTop: $("#get_two").offset().top},
        'slow');
        }
         }, 500);
      },
       scrollone4(){ 
        setTimeout(function() {
      const mediaQuery = window.matchMedia('(max-width: 767px)')
      if (mediaQuery.matches) {
        $('html,body').animate({ 
        scrollTop: $("#get_two").offset().top},
        'slow');
      }
       }, 500);
      }

    },
    mounted() {
      document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
      store.dispatch("Home/getHomeData").then(() => {});
    },
  };
  
//   $("button").click(function() {
//     $('html,body').animate({
//         scrollTop: $(".second").offset().top},
//         'slow');
// });
</script>